import React from "react";
import { Link } from "react-router-dom";
import NotFoundImage from "../../images/404-traveler.svg";
import "./not-found.scss";

export default function NotFoundPage() {
  return (
    <div className="not-found-page">
      <div>
        <h2 className="circular-bold">Welcome traveler</h2>
        <br />
        <img alt="page-not-found-graphic" src={NotFoundImage} />
        {/* <div className="header circular-bold">Where ya' off to?</div> */}
        <br />
        <br />
        <div className="description">
          You can login to Tripgrid by{" "}
          <Link
            onClick={() => (window as any).auth0Client.loginWithRedirect()}
            to="/"
          >
            clicking here
          </Link>
          .
        </div>
      </div>
    </div>
  );
}
