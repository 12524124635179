import React, { ComponentClass, FunctionComponent } from "react";
import { Switch, Redirect, Route, matchPath } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";
import { resolve } from "inversify-react";
import classnames from "classnames";

import { AppContext, AppSettings } from "./context";
import ModalRoot from "./context/modal/modal-root";
import env from "./utils/env";

import {
  IImpersonateService,
  ImpersonateService
} from "./services/impersonate.service";

import CompanyModel from "./models/company.model";
import TemplateModel from "./models/template.model";
import UserModel from "./models/user.model";

import AdminPage from "./pages/admin";
import ConcurPage from "./pages/concur";
import CollectionsPage from "./pages/collections";
import CollectionViewPage from "./pages/collection-view";
import DashboardPage from "./pages/dashboard";
import DocumentBuilderPage from "./pages/document-builder";
import GridReportsPage from "./pages/grid-reports";
import GridsPage from "./pages/grids";
import GridViewPage from "./pages/grid-view";
import InvitePage from "./pages/registration/invite";
import LoginPage from "./pages/login";
import LogoutPage from "./pages/logout";
import PeoplePage from "./pages/people";
import RegistrationPage from "./pages/registration";
import SandboxPage from "./pages/registration/sandbox";
import SettingsPage from "./pages/settings";
import TagEditorPage from "./pages/settings/sub-pages/tag-editor";
import TemplateEditor from "./pages/settings/sub-pages/template-editor";
import NotFoundPage from "./pages/not-found";

import AppSidebar from "./components/app-sidebar";
import AppStatus from "./components/app-status";
import HtmlTitle from "./components/html-title"; // sets dynamic title in the document head
import ImpersonateBar from "./components/impersonate-bar";
import Lottie from "./components/lottie";
import ModalDeepLinker from "./components/dialogs/modal-deep-linker";
import ScrollToTop from "./components/scroll-to-top"; // sets scroll position to top of page when routing between different page components

import HowieAnimationData from "./images/lotties/howie-loader.json";

export interface AppPageProps {
  user: UserModel;
  company: CompanyModel;
  settings: AppSettings;
  templates: TemplateModel[];
}

type AppRoute = {
  pageComponent:
    | ComponentClass<Pick<any, string | number | symbol>>
    | FunctionComponent<AppPageProps>;
  htmlTitle: string;
  isAuthRequired: boolean;
};

interface State {}

const routes: { [index: string]: AppRoute } = {
  "/": {
    pageComponent: DashboardPage,
    htmlTitle: "",
    isAuthRequired: true
  },

  // Tripgrid Employee Admin Controls
  "/admin": {
    pageComponent: AdminPage,
    htmlTitle: "Admin",
    isAuthRequired: true
  },

  // Collections
  "/collections": {
    pageComponent: CollectionsPage,
    htmlTitle: "Bookings",
    isAuthRequired: true
  },
  "/collections/:id": {
    pageComponent: CollectionViewPage,
    htmlTitle: "Collection View",
    isAuthRequired: true
  },

  // Concur Connect Registration
  "/concur": {
    pageComponent: ConcurPage,
    htmlTitle: "Concur Connect",
    isAuthRequired: false
  },

  // All Travel
  "/dashboard": {
    pageComponent: DashboardPage,
    htmlTitle: "Dashboard",
    isAuthRequired: true
  },

  // Document Builder
  "/documents/:id/build": {
    pageComponent: DocumentBuilderPage,
    htmlTitle: "Document Builder",
    isAuthRequired: true
  },
  "/documents/:id/shared/:shareHash": {
    pageComponent: DocumentBuilderPage,
    htmlTitle: "Shared Document",
    isAuthRequired: false
  },

  // Grids
  "/grids": {
    pageComponent: GridsPage,
    htmlTitle: "Grids",
    isAuthRequired: true
  },
  "/grids/:id": {
    pageComponent: GridViewPage,
    htmlTitle: "Grid View",
    isAuthRequired: true
  },
  "/grids/:id/reports/:reportType": {
    pageComponent: GridReportsPage,
    htmlTitle: "Report",
    isAuthRequired: true
  },

  // Workspace User Directory
  "/people": {
    pageComponent: PeoplePage,
    htmlTitle: "People",
    isAuthRequired: true
  },

  // Workspace Settings
  "/settings": {
    pageComponent: SettingsPage,
    htmlTitle: "Settings",
    isAuthRequired: true
  },
  "/settings/tags": {
    pageComponent: TagEditorPage,
    htmlTitle: "Tag Editor",
    isAuthRequired: true
  },
  "/settings/templates/:templateType(event|profile|flight|accommodation|rail|transportation)": {
    pageComponent: TemplateEditor,
    htmlTitle: "Field Editor",
    isAuthRequired: true
  },

  // Workspace Registration
  "/signup": {
    pageComponent: RegistrationPage,
    htmlTitle: "Sign Up",
    isAuthRequired: false
  },
  "/invites/:id/:token": {
    pageComponent: InvitePage,
    htmlTitle: "Invite Sign Up",
    isAuthRequired: false
  },
  "/sandbox": {
    pageComponent: SandboxPage,
    htmlTitle: "Create Sandbox",
    isAuthRequired: false
  },

  // Login
  "/login": {
    pageComponent: LoginPage,
    htmlTitle: "Login",
    isAuthRequired: false
  },
  "/logout": {
    pageComponent: LogoutPage,
    htmlTitle: "Logout",
    isAuthRequired: false
  }
};

const unAuthedPaths = Object.entries(routes)
  .filter(([path, appRoute]: [string, AppRoute]) => !appRoute.isAuthRequired)
  .map(([path, appRoute]: [string, AppRoute]) => path);

// matchPath documentation: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/matchPath.md

const isAuthPath = (url: string): boolean =>
  unAuthedPaths.every((path) => !matchPath(url, { path, exact: true }));

const isAdminPath = (url: string): boolean =>
  ["/settings"].every((path) => matchPath(url, { path })); // don't use exact to include nested routes

const isSuperAdminPath = (url: string): boolean =>
  ["/admin"].every((path) => matchPath(url, { path })); // don't use exact to include nested routes

class AppRouter extends React.Component<RouteComponentProps, State> {
  private currentPage: string = "";

  @resolve(ImpersonateService)
  impersonateService!: IImpersonateService;

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    this.trackCurrentPage();

    if (
      (window as any).auth0 &&
      (window as any).auth0.createAuth0Client instanceof Function
    ) {
      (window as any).auth0Client = await (window as any).auth0.createAuth0Client(
        {
          domain: "tripgrid.auth0.com",
          clientId: "EVvO90ORBI7AdyVjbfB1l484o9P7NnrV",
          authorizationParams: {
            redirect_uri: window.location.origin,
            audience: "tg-booking-api"
          }
        }
      );

      let shouldLoadAuth = false;

      if (
        window.location.search.includes("state=") &&
        (window.location.search.includes("code=") ||
          window.location.search.includes("error="))
      ) {
        await (window as any).auth0Client.handleRedirectCallback();
        shouldLoadAuth = true;
      } else if (!window.localStorage.getItem("auth_token")?.length) {
        if (await (window as any).auth0Client.isAuthenticated()) {
          shouldLoadAuth = true;
        } else {
          await (window as any).auth0Client.loginWithRedirect();
        }
      }

      if (shouldLoadAuth) {
        const accessToken = await (window as any).auth0Client.getTokenSilently(
          {}
        );
        const { token: authToken } = await fetch(env.api + "/auth/login", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }).then((r) => r.json());

        window.history.replaceState(
          {},
          document.title,
          "/#authToken=" + authToken
        );
        window.location.reload();
      }
    }
  }

  componentDidUpdate() {
    this.trackCurrentPage();
  }

  private trackCurrentPage() {
    const { location } = this.props;
    const { pathname } = location;

    if (pathname === this.currentPage) {
      return;
    }

    this.currentPage = pathname;

    if (
      env.segment &&
      (window as any).analytics &&
      (window as any).analytics.page instanceof Function
    ) {
      (window as any).analytics.page();
    }
  }

  render() {
    const impersonationId = this.impersonateService.getId();

    const { location } = this.props;
    const { pathname } = location;

    const hideSidebar = ["/documents/", "/reports/"].some((s) =>
      pathname.includes(s)
    );

    return (
      <AppContext.Consumer>
        {(appState) => {
          const {
            isAuthed,
            isLoading,
            company,
            settings,
            templates,
            user
          } = appState;

          const isSuperAdmin = isAuthed && user?.isSuperAdmin();
          const isPrimaryAdmin = isAuthed && user?.primary;

          return (
            <div
              id="app"
              className={classnames({
                "impersonate-active": Boolean(impersonationId)
              })}
            >
              <ModalRoot />
              {!isLoading && !hideSidebar && isAuthed && (
                <AppSidebar user={user} company={company} settings={settings} />
              )}
              {!isAuthPath(pathname) && <AppStatus />}

              <div id="app-page">
                {isLoading && (
                  <div className="app-loading">
                    <div className="howie-loader-frame">
                      <Lottie
                        loop={true}
                        play={true}
                        animationData={HowieAnimationData}
                        rendererSettings={{
                          preserveAspectRatio: "xMidYMid slice"
                        }}
                      />
                    </div>
                  </div>
                )}

                {!isLoading && (
                  <Switch>
                    {Object.entries(routes).map(
                      ([
                        path,
                        { pageComponent: PageComponent, isAuthRequired }
                      ]) => {
                        if (!isAuthed && isAuthRequired) {
                          return null;

                          // return (
                          //   <Redirect
                          //     key={path}
                          //     exact
                          //     from={path}
                          //     to={"/login?expired"}
                          //   />
                          // );
                        }

                        const RedirectEl = (
                          <Redirect key={path} exact from={path} to={"/"} />
                        );

                        // company workspace admin paths
                        if (
                          !isPrimaryAdmin &&
                          !isSuperAdmin &&
                          isAdminPath(path)
                        ) {
                          return RedirectEl;
                        }

                        // Tripgrid employee admin paths
                        if (!isSuperAdmin && isSuperAdminPath(path)) {
                          return RedirectEl;
                        }

                        // must be signed-out
                        if (
                          isAuthed &&
                          ["/login", "/signup"].indexOf(path) > -1
                        ) {
                          return RedirectEl;
                        }

                        return (
                          <Route exact key={path} path={path}>
                            <HtmlTitle title={routes[path].htmlTitle} />
                            <ScrollToTop />
                            <PageComponent
                              user={user}
                              company={company}
                              settings={settings}
                              templates={templates}
                            />
                          </Route>
                        );
                      }
                    )}

                    <Route component={NotFoundPage} />
                  </Switch>
                )}
              </div>

              {!isLoading && isAuthed && <ModalDeepLinker />}
              {!isLoading && isAuthed && impersonationId && <ImpersonateBar />}
            </div>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

export default withRouter(AppRouter);
